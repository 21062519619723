<template>
  <div
    ref="refPinnedContainer"
    :style="[pinedStyle, { top: '0px !important' }]"
    :class="pinnedContainerClass"
    class=""
  >
    <div ref="refSlotContainer" v-bind="$attrs" :class="slotContainerClass">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineOptions } from "vue";
import {
	type WidthScrollContainerEmits,
	type WidthScrollContainerProps,
	useWidthScrollContainer,
} from "./hooks/useWidthScrollContainer";

defineOptions({
	inheritAttrs: false,
});

const props = defineProps<WidthScrollContainerProps>();
const emits = defineEmits<WidthScrollContainerEmits>();

const {
	scrollToWidth,
	scrollToHeight,
	pinnedContainerClass,
	slotContainerClass,
	refPinnedContainer,
	refSlotContainer,
} = useWidthScrollContainer(emits, props);
</script>
<style scoped lang="scss">
.slot-container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.3s;

  &--width {
    width: v-bind(scrollToWidth);
  }

  &--height {
    height: v-bind(scrollToHeight);
  }

  &--pinned {
    white-space: normal;
    overflow: hidden;
    text-overflow: clip;
  }
}
</style>
