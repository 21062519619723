<template>
  <v-card
    class="elevation-1 pr-4 pl-2 py-2 bg-cs_background rounded-pill d-flex align-center justify-space-between"
    :class="[selected ? 'bg-cs_surface-bright' : 'bg-cs_background']"
    @mouseover="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="mr-5 d-flex align-center">
      <v-checkbox-btn
        v-if="selectable && isHovering"
        style="width: 32px; height: 32px"
        width="32px"
        height="32px"
        v-model="modelSelected"
        @click.stop
      ></v-checkbox-btn>

      <v-btn
        v-else
        width="32px"
        height="32px"
        icon
        variant="text"
        class="bg-surface-variant"
        :color="icon?.buttonColor ?? 'primary'"
      >
        <v-icon
          :icon="icon?.name ?? 'mdi-plus'"
          size="24px"
          :color="icon?.color ?? 'on-primary'"
        ></v-icon>
      </v-btn>
    </div>
    <div
      class="flex-grow-1"
      style="display: grid; grid-template-rows: 32px; align-items: center"
      :style="`grid-template-columns: 1fr repeat(${columns - 1}, 0.5fr)`"
    >
      <slot :data="{ item, isHovering }"></slot>
    </div>
  </v-card>
</template>

<script setup lang="ts" generic="T">
import { ref, watch } from "vue";

defineProps<{
  selectable?: boolean;
  columns?: number;
  icon?: { name?: string; color?: string; buttonColor?: string };
  item: T;
}>();

const modelSelected = defineModel<boolean>("selected");

const isHovering = ref(false);

function handleMouseEnter() {
  isHovering.value = true;
}

function handleMouseLeave() {
  if (!modelSelected.value) isHovering.value = false;
}

watch(
  modelSelected,
  (value) => {
    if (value) {
      isHovering.value = true;
      return;
    }
    isHovering.value = false;
  },
  { immediate: true }
);
</script>
