import { customEvents } from "@clearsale/one-lib-events";

export function dispatchConfigEvent() {
  customEvents.appLoadedEvent.dispatch({
    appName: "others-apps",
    payload: {
      authFlow: process.env.VUE_APP_AUTH_FLOW ?? "",
      authTenant: process.env.VUE_APP_AUTH_TENANT ?? "",
      authUrl: process.env.VUE_APP_AUTH_URL ?? "",
      authRedirectOne: process.env.VUE_APP_REDIRECT_URI_ONE ?? "",
    },
  });
}
