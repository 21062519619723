<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="model = false"></v-btn>

        <v-toolbar-title>Developer</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn
            text="Save"
            variant="text"
            @click="saveDeveloperConfig"
          ></v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-list lines="two" subheader>
        <v-list-subheader>General</v-list-subheader>

        <v-list-item
          subtitle="Add a secrete key to load the SSO"
          title="Secret key"
          link
        >
          <v-text-field
            class="mt-4"
            label="Secret key"
            v-model="secretSSO"
          ></v-text-field>
        </v-list-item>

        <v-list-item
          subtitle="Add a secrete token to load the BIs"
          title="Report BI token"
          link
        >
          <v-text-field
            class="mt-4"
            label="BI token"
            v-model="tokenBI"
          ></v-text-field
        ></v-list-item>

        <v-list-item
          subtitle="Add a report ID to load the BIs"
          title="Report ID"
          link
        >
          <v-text-field
            class="mt-4"
            label="Report ID"
            v-model="reportId"
          ></v-text-field
        ></v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useLocalStorage } from "@/utils/localstorage/localstorage";

const tokenBI = ref("");
const secretSSO = ref("");
const reportId = ref("");
const model = defineModel("dialog", { default: false });
const { setStorage, getStorage } = useLocalStorage();

function saveDeveloperConfig() {
  model.value = false;

  setStorage("secret", secretSSO.value);
  setStorage("token-bi", tokenBI.value);
  setStorage("report-id", reportId.value);
}
onMounted(() => {
  secretSSO.value = getStorage("secret") ?? "";
  tokenBI.value = getStorage("token-bi") ?? "";
  reportId.value = getStorage("report-id") ?? "";
});
</script>
