import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref } from "vue";
import { useLocalStorage } from "@/utils/localstorage/localstorage";


export default /*@__PURE__*/_defineComponent({
  __name: 'DeveloperMode',
  props: {
    "dialog": { default: false },
    "dialogModifiers": {},
  },
  emits: ["update:dialog"],
  setup(__props) {

const tokenBI = ref("");
const secretSSO = ref("");
const reportId = ref("");
const model = _useModel(__props, "dialog");
const { setStorage, getStorage } = useLocalStorage();

function saveDeveloperConfig() {
  model.value = false;

  setStorage("secret", secretSSO.value);
  setStorage("token-bi", tokenBI.value);
  setStorage("report-id", reportId.value);
}
onMounted(() => {
  secretSSO.value = getStorage("secret") ?? "";
  tokenBI.value = getStorage("token-bi") ?? "";
  reportId.value = getStorage("report-id") ?? "";
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((model).value = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                icon: "mdi-close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (model.value = false))
              }),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Developer")
                ])),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_toolbar_items, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    text: "Save",
                    variant: "text",
                    onClick: saveDeveloperConfig
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_list, {
            lines: "two",
            subheader: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_subheader, null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("General")
                ])),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                subtitle: "Add a secrete key to load the SSO",
                title: "Secret key",
                link: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    class: "mt-4",
                    label: "Secret key",
                    modelValue: secretSSO.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((secretSSO).value = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                subtitle: "Add a secrete token to load the BIs",
                title: "Report BI token",
                link: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    class: "mt-4",
                    label: "BI token",
                    modelValue: tokenBI.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((tokenBI).value = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                subtitle: "Add a report ID to load the BIs",
                title: "Report ID",
                link: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    class: "mt-4",
                    label: "Report ID",
                    modelValue: reportId.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((reportId).value = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})