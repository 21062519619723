<template>
  <v-card
    class="rounded-circle d-flex justify-center align-center cursor-pointer bg-cs_background"
    style="width: 240px; height: 240px"
  >
    <slot></slot>
  </v-card>
</template>

<script setup lang="ts"></script>
