import { computed } from "vue";
import { useTheme } from "vuetify";


export enum ITransactionStatus {
  None = 0,
  Approved = 1,
  Denied = 2,
  Analyzing = 3,
  Canceled = 4
}

interface UseClassByThemeProps {
  light: string;
  dark: string;
}

export interface UseClassByThemeStatusProps {
  light: Record<ITransactionStatus, string>;
  dark: Record<ITransactionStatus, string>;
}


export function useClassByTheme({ dark, light }: UseClassByThemeProps) {

  const { name } = useTheme()

  return computed(() => {
    const classByTheme = {
      light,
      dark,
    };

    return classByTheme[name.value as keyof typeof classByTheme]; // Return the variable or use it in a function call
  });
}


export function useClassByThemeStatus({ dark, light }: UseClassByThemeStatusProps) {
  const { name } = useTheme()

  return computed(() => {
    const classByTheme = {
      light,
      dark
    };

    return classByTheme[name.value as keyof typeof classByTheme]; // Return the variable or use it in a function call
  });
}
