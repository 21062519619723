export const label = {
  AddNewPage: {
    title: "Adicionar",
    information: "Informações",
    name: "Nome",
    Template: {
      title: "Template",
      BaseReport: {
        title: "Relatório base",
        content: "Comece com nosso template",
      },
      BlankReport: {
        title: "Relatório vazio",
        content: "Construa seu próprio visual",
      },
    },
    Save: {
      title: "Salvar",
    },
  },
  Modals: {
    DiscardChanges: {
      title: "Descartar mudanças",
      content:
        "Você tem alterações não salvas. Tem certeza que deseja descartá-las?",
      cancel: "Cancelar",
      discard: "Descartar",
    },
    DeleteFile: {
      title: "Excluir arquivo",
      content: "Tem certeza que deseja apagar permanentemente esse arquivo?",
      cancel: "Cancelar",
      delete: "Excluir",
    },
  },
  EditViewReportPage: {
    RotateDevice: {
      title: "Gire seu dispositivo para ver em tela cheia",
    },
    Edit: {
      title: "Editar",
    },
    Save: {
      title: "Salvar",
    },
  },
  TopMenu: {
    Search: "Procurar na ClearSale",
  },
  UserMenu: {
    language: {
      title: "Idioma",
      english: "English",
      portuguese: "Português",
      spanish: "Español",
    },
    timezone: "Fuso horário",
    theme: {
      title: "Tema",
      dark: "Escuro",
      light: "Claro",
    },
    signout: "Sair",
  },
  ReportsPage: {
    title: "Nexus",
    AddReportDesktop: {
      title: "Clique aqui para criar seu primeiro dashboard",
    },
    AddReportMobile: {
      title: "Toque aqui para criar seu primeiro dashboard",
    },
    AddReport: {
      title: "Adicionar",
    },
    ButtonFilter: {
      Name: {
        title: "Nome",
      },
      Owner: {
        title: "Dono",
      },
      DateModified: {
        title: "Data de modificação",
      },
    },
    FilteLimitReached: {
      title: "Limite de arquivos atingido. Considere apagar alguns.",
    },
  },
};
