import { DirectiveBinding } from 'vue';

interface DoubleTouchDirectiveBinding extends DirectiveBinding {
  value: (event: Event) => void; // Função chamada quando o duplo toque é detectado
}

interface CustomTouchEvent extends HTMLElement {
  __vueDoubleTapHandler__: (event: TouchEvent) => void;
}

interface CustomClickEvent extends HTMLElement {
  __vueDoubleTapHandler__: (event: Event) => void;
}

export const vDoubleTouch = {
  beforeMount(el: HTMLElement, binding: DoubleTouchDirectiveBinding) {
    let lastTouchEnd = 0;
    const DOUBLE_TAP_THRESHOLD = 300; // Tempo em milissegundos para considerar como duplo toque

    const handleTouchEnd = (event: TouchEvent) => {
      const now = Date.now();
      if (now - lastTouchEnd <= DOUBLE_TAP_THRESHOLD) {
        binding.value(event); // Chama a função passada como valor da diretiva
      }
      lastTouchEnd = now;
    };

    (el as CustomTouchEvent).__vueDoubleTapHandler__ = handleTouchEnd;
    el.addEventListener('touchend', handleTouchEnd);
  },
  unmounted(el: HTMLElement) {
    el.removeEventListener('touchend', (el as CustomTouchEvent).__vueDoubleTapHandler__);
  },
};


export const vTripleTouch = {
  beforeMount(el: HTMLElement, binding: DoubleTouchDirectiveBinding) {
    let lastTouchEnd = 0;
    const TRIPLE_TAP_THRESHOLD = 300; // Tempo em milissegundos para considerar como triplo toque
    let taps = 0;

    const handleTouchEnd = (event: Event) => {
      const now = Date.now();
      if (now - lastTouchEnd <= TRIPLE_TAP_THRESHOLD) {
        taps++;
        if (taps === 3) {
          binding.value(event); // Chama a função passada como valor da diretiva
          taps = 0;
        }
      } else {
        taps = 1;
      }
      lastTouchEnd = now;
    };

    (el as CustomTouchEvent).__vueDoubleTapHandler__ = handleTouchEnd;
    // el.addEventListener('touchend', handleTouchEnd);
    el.addEventListener('click', handleTouchEnd)
  },
  unmounted(el: HTMLElement) {
    // el.removeEventListener('touchend', (el as CustomTouchEvent).__vueDoubleTapHandler__);
    el.removeEventListener('click', (el as CustomClickEvent).__vueDoubleTapHandler__);
  },
};


