import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-end" }
const _hoisted_2 = {
  key: 0,
  class: "header d-flex justify-space-between mb-2"
}
const _hoisted_3 = { class: "content d-flex align-start" }
const _hoisted_4 = { class: "mr-4" }
const _hoisted_5 = { key: 0 }

import { onMounted, ref } from "vue";
import { swipeUtility } from "@/utils/swipeUtility";
import { useTheme } from "vuetify";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemMobile',
  props: /*@__PURE__*/_mergeModels({
    header: { type: Boolean, default: true },
    selectable: { type: Boolean },
    columns: {},
    icon: {},
    item: {}
  }, {
    "selected": { type: Boolean },
    "selectedModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["select"], ["update:selected"]),
  setup(__props: any, { emit: __emit }) {

const { name: theme } = useTheme();

const props = __props;

const emits = __emit;

const modelSelected = _useModel<boolean>(__props, "selected");

const cardItemRef = ref<HTMLElement | null>(null);
const hiddenContent = ref<HTMLElement | null>(null);

function activeSelected() {
  if (props.selectable) modelSelected.value = true;
}

function handleSelectReport() {
  emits("select", props.item);
}

onMounted(() => {
  if (cardItemRef.value && hiddenContent.value) {
    let swipe = swipeUtility(cardItemRef.value, {
      leftElement: hiddenContent.value,
      rightElement: cardItemRef.value,
      type: "LEFT",
    });

    // if (mobile && props.active) {
    //   swipe.hammer.destroy();
    //   return;
    // }
    swipe.onLoadEvents(swipe.hammer);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "position-relative rounded-xl",
    elevation: "1",
    onClick: _withModifiers(handleSelectReport, ["stop"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["pa-4", 
          _unref(theme) === 'dark'
            ? 'bg-cs_information_container'
            : 'bg-cs_ref_palette_information95'
        ])
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(["pa-4", 
          _unref(theme) === 'dark'
            ? 'bg-cs_information_container'
            : 'bg-cs_ref_palette_information95'
        ]),
          style: _normalizeStyle({
          height: cardItemRef.value?.getBoundingClientRect().height + 'px',
          borderRadius: '0px 24px 24px 0px',
        }),
          ref_key: "hiddenContent",
          ref: hiddenContent
        }, [
          _renderSlot(_ctx.$slots, "back", {
            data: { item: _ctx.item }
          }, () => [
            _cache[1] || (_cache[1] = _createTextVNode(" conteudo escondido askjdlaj askdladj "))
          ])
        ], 6)
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["position-absolute top-0 left-0 right-0 pa-4 elevation-1 rounded-xl", modelSelected.value ? 'bg-cs_surface-bright' : 'bg-cs_background']),
        ref_key: "cardItemRef",
        ref: cardItemRef
      }, [
        (_ctx.header ?? true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "header", {
                data: { item: _ctx.item }
              }, () => [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-caption" }, "#123456789", -1)),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_icon, {
                    icon: "mdi-plus",
                    size: "8px"
                  }),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-subtitle" }, "Approved", -1))
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.selectable && modelSelected.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_v_btn, {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
                () => {
                  modelSelected.value = !modelSelected.value;
                }
              , ["stop"])),
                    icon: "",
                    color: "tertiary-container",
                    width: "40px",
                    height: "40px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        size: "12px",
                        icon: "mdi-circle",
                        class: "text-cs_on_tertiary_container"
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _renderSlot(_ctx.$slots, "icon", {
                  key: 1,
                  data: { item: _ctx.item, activeSelected: activeSelected }
                }, () => [
                  _createVNode(_component_v_btn, {
                    width: "40px",
                    height: "40px",
                    icon: "",
                    variant: "text",
                    onClick: _withModifiers(activeSelected, ["stop"]),
                    onTouchstart: _withModifiers(activeSelected, ["stop"]),
                    class: "bg-surface-variant",
                    color: _ctx.icon?.buttonColor ?? 'primary'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: _ctx.icon?.name ?? 'mdi-plus',
                        size: "24px",
                        color: _ctx.icon?.color ?? 'on-primary'
                      }, null, 8, ["icon", "color"])
                    ]),
                    _: 1
                  }, 8, ["color"])
                ])
          ]),
          _createElementVNode("div", {
            class: "flex-grow-1",
            style: _normalizeStyle(`display: grid;  grid-template-columns: repeat(${
            _ctx.columns ?? 2
          }, 1fr); align-items:center; `)
          }, [
            _renderSlot(_ctx.$slots, "default", {
              data: { item: _ctx.item }
            }, () => [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "d-flex flex-column" }, [
                _createElementVNode("span", null, "Long store name"),
                _createElementVNode("time", { datetime: "" }, "12:57 PM")
              ], -1)),
              _cache[5] || (_cache[5] = _createElementVNode("div", { style: {"justify-self":"end"} }, "$999,999.99", -1))
            ])
          ], 4)
        ])
      ], 2)), [
        [_directive_ripple]
      ])
    ]),
    _: 3
  }))
}
}

})