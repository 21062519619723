// Styles Globais
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Tokens Clearsale
import { tokens } from "@clearsale/tokens-ds";

// Vuetify
import { createVuetify, ThemeDefinition } from "vuetify";
import { md3 } from "vuetify/blueprints";
import {
  loadThemeByName,
  getTokensWithoutPrefixMDUI,
} from "./clearMaterialToVuetify";

const variables = {
  ...tokens.elevation,
  ...tokens.motion,
  ...tokens.radii,
  ...tokens.state,
  ...tokens.typography,
  ...getTokensWithoutPrefixMDUI(),
  "breakpoint-sm": tokens.breakpoints.sm,
  "breakpoint-md": tokens.breakpoints.md,
  "breakpoint-lg": tokens.breakpoints.lg,
  "breakpoint-xl": tokens.breakpoints.xl,
};

const dark: ThemeDefinition = {
  dark: true,
  colors: loadThemeByName(),
  variables,
};

const light: ThemeDefinition = {
  dark: false,
  colors: loadThemeByName("light"),
  variables,
};

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    display: {
      thresholds: tokens.breakpoints,
    },
    blueprint: md3,
    theme: {
      defaultTheme: localStorage.getItem("theme") ?? "light",
      themes: {
        light,
        dark,
      },
    },
  }
);
