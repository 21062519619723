<template>
  <div
    class="d-flex flex-column ga-4 mx-auto"
    :class="!$state.isLandScape ? 'px-4' : 'px-0'"
    :style="{ maxWidth: lgAndUp ? '86vw' : '1040px' }"
    v-if="reportEdit"
  >
    <div
      class="w-full bg-cs_secondary_container"
      :class="!$state.isLandScape ? 'rounded-xl' : ''"
      :style="{
        height: !$state.isLandScape
          ? 'calc(100vh - 80px - 60px - 48px)'
          : '100vh',
        overflow: 'hidden',
      }"
    >
      <ReportBI
        v-if="reportEdit.embedToken.token"
        :mode="ViewMode.View"
        :report="reportEdit"
        :report-id="reportIdLoad"
      ></ReportBI>
    </div>
    <div
      class="d-flex justify-space-between align-center"
      v-if="!$state.isLandScape"
    >
      <div class="d-flex align-center ga-4">
        <v-btn
          color="cs_surface_container"
          icon="mdi-arrow-left"
          class="elevation-0"
          size="40px"
          @click="
            () => {
              router.back();
            }
          "
          data-testid="back-button"
        ></v-btn>
        <div style="max-width: 210px">
          <div>
            {{ reportEdit.reportName }}
          </div>

          <div>
            {{ reportEdit.ownerName }}
          </div>
        </div>
      </div>
      <div class="d-flex ga-4">
        <v-btn
          height="40px"
          :color="
            themeName === 'dark'
              ? 'cs_ref_palette_information30'
              : 'cs_ref_palette_information95'
          "
          class="elevation-0"
          @click="handleRedirectToEdit"
        >
          <div class="d-flex ga-2 text-cs_tertiary">
            <v-icon icon="mdi-pencil"></v-icon>
            {{ t(label.EditViewReportPage.Edit.title) }}
          </div>
        </v-btn>
        <v-btn
          @click="confirmDeleteReport = true"
          icon="mdi-trash-can-outline"
          size="40px"
          class="elevation-0"
          :color="
            themeName === 'dark'
              ? 'cs_ref_palette_information30'
              : 'cs_ref_palette_information95'
          "
        >
          <v-icon
            icon="mdi-pencil"
            class="mdi-trash-can-outline text-cs_tertiary"
          ></v-icon>
        </v-btn>
      </div>
    </div>
  </div>

  <v-dialog v-model="confirmDeleteReport" width="auto" scrim="black">
    <DeleteModal
      v-if="confirmDeleteReport"
      @cancel="confirmDeleteReport = false"
      @confirm="handleDeleteReport()"
    ></DeleteModal>
  </v-dialog>

  <SnackbarClose
    v-if="!mdAndUp && !$state.isLandScape"
    v-model="rotateSmartPhoneSnackbar"
    @close="rotateSmartPhoneSnackbar = false"
  >
    <span>{{ t(label.EditViewReportPage.RotateDevice.title) }}</span>
  </SnackbarClose>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useDisplay, useTheme } from "vuetify";
import { useReportsStore } from "@/store/ReportsStore";
import {
  deleteReportAPI,
  getReportEmbed,
  ResponseReportEdit,
} from "@/api/resources/ReportsResource/nexusResource";
import SnackbarClose from "@/components/nexus/SnackbarClose/SnackbarClose.vue";
import ReportBI from "@/components/nexus/ReportBI/ReportBI.vue";
import DeleteModal from "@/components/nexus/DeleteModal/DeleteModal.vue";
import { useLocalStorage } from "@/utils/localstorage/localstorage";
import { routeNames } from "@/router";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";
import { customEvents } from "@clearsale/one-lib-events";

enum ViewMode {
  View = 0,
  Edit = 1,
}

const { t } = useI18n();
const { $state, $patch } = useReportsStore();
const { name: themeName } = useTheme();

const { mdAndUp, lgAndUp } = useDisplay();
const router = useRouter();
const route = useRoute();
const { getStorage } = useLocalStorage();

const confirmDeleteReport = ref(false);
const rotateSmartPhoneSnackbar = ref(false);

const reportEdit = ref<ResponseReportEdit | undefined>(undefined);
const reportIdLoad =
  computed(() => route.params.id as string) &&
  process.env.VUE_APP_ENV !== "development"
    ? (route.params.id as string)
    : getStorage("report-id") ?? "1711d2b9-6375-40bd-b273-22e64d9b4fa7";

function handleRedirectToEdit() {
  router.push({
    name: routeNames.editReport,
    params: { id: route.params.id },
  });
}

function handleDeleteReport() {
  if (route.params.id as string) {
    deleteReportAPI(route.params.id as string).then(() => {
      router.push({
        name: routeNames.reports,
      });
    });
  }
}

onMounted(() => {
  rotateSmartPhoneSnackbar.value = true;
});

onMounted(() => {
  if ($state.isLandScape) {
    customEvents.menuEvent.displayToggled.dispatch(false);
  }
  if (route.params.id as string) {
    getReportEmbed(route.params.id as string).then((response) => {
      reportEdit.value = response;
    });
    return;
  }
  router.push({
    name: routeNames.reports,
  });
});

watch(
  () => $state.isLandScape,
  () => {
    customEvents.menuEvent.displayToggled.dispatch(
      $state.isLandScape ? false : true
    );
  }
);

onUnmounted(() => {
  customEvents.menuEvent.displayToggled.dispatch(true);
});
</script>
