<template>
  <v-snackbar v-model="model" color="inverse-surface">
    <slot>
      <span class="text-inverse-on-surface">
        {{ t(label.ReportsPage.FilteLimitReached.title) }}
      </span>
    </slot>

    <template v-slot:actions>
      <v-btn
        color="inverse-on-surface"
        variant="text"
        @click="handleCloseSnackbar"
        icon="mdi-close"
      >
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const model = defineModel({ default: false });
const emits = defineEmits<(e: "close") => void>();

function handleCloseSnackbar() {
  emits("close");
}
</script>

<style lang="scss">
.v-snackbar__wrapper {
  bottom: 5rem;
  position: absolute !important;
}
</style>
