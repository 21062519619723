import type { Report } from "@/api/resources/ReportsResource/nexusResource";
import { useLocalStorage } from "@/utils/localstorage/localstorage";

export interface ReportAPI {
  results: Report[];
}

export const reportsDB: ReportAPI = {
  results: [
    {
      id: "1",
      name: "POWER BI 1",
      ownerName: "Owner 1",
      updatedAt: new Date("2024-08-26T08:24:00"),
    },
    {
      id: "12",
      name: "POWER BI 2",
      ownerName: "Owner 2",
      updatedAt: new Date("2024-07-18T15:24:22"),
    },
    {
      id: "123",
      name: "POWER BI 3",
      ownerName: "Owner 3",
      updatedAt: new Date("2024-07-18T12:24:00"),
    },
    {
      id: "1234",
      name: "POWER BI 4",
      ownerName: "Owner 4",
      updatedAt: new Date("2024-06-18T15:24:00"),
    },
    {
      id: "12345",
      name: "POWER BI 5",
      ownerName: "Owner 5",
      updatedAt: new Date("2024-05-18T15:24:00"),
    }
  ]
}

export const suggestionReportOwnersDB = reportsDB.results.map((report) => ({
  id: report.id,
  value: report.ownerName,
  subValue: "",
}));

export const suggestionReportIDsDB = reportsDB.results.map((report) => ({
  id: report.id,
  value: report.name,
  subValue: "",
}));

export const suggestionReportNamesDB = reportsDB.results
  .map(r => ({ id: r.id, value: r.name, subValue: '' }));

const { getStorage } = useLocalStorage();

export const reportsEmbedDB = reportsDB.results.map((report) => {
  return {
    id: report.id,
    result: {
      ownerName: report.ownerName,
      reportName: report.name,
      updatedAt: report.updatedAt,
      embedToken: {
        token: getStorage("token-bi") || "",
        tokenId: "81e47435-e540-4735-b5a1-35e81620853b",
        expiration: "2024-09-05T20:18:14Z",
      },
      reportId: "1711d2b9-6375-40bd-b273-22e64d9b4fa7",
      embedUrl:
        "https://app.powerbi.com/reportEmbed?reportId=1711d2b9-6375-40bd-b273-22e64d9b4fa7&groupId=6445d8c6-cd38-4f63-b366-5b7b85e46235&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
      datasetIds: ["03891d64-e0d3-4b5c-b71d-5d79411b7bb1"],
    },
  };
});
