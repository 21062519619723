import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]

import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";
import { tokens } from "@clearsale/tokens-ds";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomHero',
  props: {
    label: {},
    srcImage: {},
    width: {},
    height: {}
  },
  setup(__props: any) {

const { t } = useI18n();
const { mdAndUp } = useDisplay();

const fontSizeLarge = tokens.typography["typescale-display-large-size"];
const fontSizeSmall = tokens.typography["typescale-display-small-size"];

const lineHeightLarge =
  tokens.typography["typescale-display-large-line-height"];
const lineHeightSmall =
  tokens.typography["typescale-display-small-line-height"];

const fontWeightLarge = tokens.typography["typescale-display-large-weight"];
const fontWeightSmall = tokens.typography["typescale-display-small-weight"];



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": _unref(generateTestId)(_unref(CustomHeroElements).Content),
    id: "hero",
    style: _normalizeStyle({
      backgroundImage: _unref(mdAndUp) ? `url('${_ctx.srcImage}')` : `url('${_ctx.srcImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionY: '0px',
      padding: `${_unref(mdAndUp) ? '1.5rem' : '0.75rem'}`,
      height: _ctx.height,
      width: _ctx.width,
    }),
    class: "title h-100 w-100 position-relative"
  }, [
    _createElementVNode("h1", {
      class: "on-surface",
      style: _normalizeStyle({
        fontSize: _unref(mdAndUp) ? _unref(fontSizeLarge) : _unref(fontSizeSmall),
        lineHeight: _unref(mdAndUp) ? _unref(lineHeightLarge) : _unref(lineHeightSmall),
        fontWeight: _unref(mdAndUp) ? _unref(fontWeightLarge) : _unref(fontWeightSmall),
      })
    }, _toDisplayString(_unref(t)(_ctx.label || "")), 5),
    _renderSlot(_ctx.$slots, "default")
  ], 12, _hoisted_1))
}
}

})