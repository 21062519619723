import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ResponseReportEdit } from "@/api/resources/ReportsResource/nexusResource";
import { factories, models, service, Embed } from "powerbi-client";
import { Ref, onMounted, ref } from "vue";
enum ViewMode {
  View = 0,
  Edit = 1,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportBI',
  props: {
    report: {},
    mode: {},
    reportId: {}
  },
  emits: ["loaded", "click"],
  setup(__props: any, { emit: __emit }) {

const container = ref<HTMLDivElement>() as Ref<HTMLDivElement>;
const props = __props;
const emit = __emit;

onMounted(async () => {
  const powerBIConfiguration = {
    settings: {
      useCustomSaveAsDialog: true,
      filterPaneEnabled: true,
      navContentPaneEnabled: true,
      layoutType: models.LayoutType.Custom,
      //layoutType: models.LayoutType.MobilePortrait,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage,
      },
    },
    viewMode:
      props.mode === ViewMode.Edit
        ? models.ViewMode.Edit
        : models.ViewMode.View,
    permissions: models.Permissions.ReadWrite,
    tokenType: models.TokenType.Embed,
    datasetId: props.report.datasetIds[0],
    accessToken: props.report.embedToken.token,
    embedUrl: props.report.embedUrl,
    id: props.reportId,
    type: "report",
  };
  const powerbi = new service.Service(
    factories.hpmFactory,
    factories.wpmpFactory,
    factories.routerFactory
  );

  powerbi.bootstrap(container.value, powerBIConfiguration);
  let embed = powerbi.embed(container.value, powerBIConfiguration) as Embed;

  embed.on("loaded", () => {
    emit("loaded", embed);
  });

  embed.on("buttonClicked", () => {
    emit("click");
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    ref_key: "container",
    ref: container
  }, null, 512))
}
}

})