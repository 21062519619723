import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mr-5 d-flex align-center" }

import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemDesktop',
  props: /*@__PURE__*/_mergeModels({
    selectable: { type: Boolean },
    columns: {},
    icon: {},
    item: {}
  }, {
    "selected": { type: Boolean },
    "selectedModifiers": {},
  }),
  emits: ["update:selected"],
  setup(__props: any) {



const modelSelected = _useModel<boolean>(__props, "selected");

const isHovering = ref(false);

function handleMouseEnter() {
  isHovering.value = true;
}

function handleMouseLeave() {
  if (!modelSelected.value) isHovering.value = false;
}

watch(
  modelSelected,
  (value) => {
    if (value) {
      isHovering.value = true;
      return;
    }
    isHovering.value = false;
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: _normalizeClass(["elevation-1 pr-4 pl-2 py-2 bg-cs_background rounded-pill d-flex align-center justify-space-between", [__props.selected ? 'bg-cs_surface-bright' : 'bg-cs_background']]),
    onMouseover: handleMouseEnter,
    onMouseleave: handleMouseLeave
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.selectable && isHovering.value)
          ? (_openBlock(), _createBlock(_component_v_checkbox_btn, {
              key: 0,
              style: {"width":"32px","height":"32px"},
              width: "32px",
              height: "32px",
              modelValue: modelSelected.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modelSelected).value = $event)),
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, null, 8, ["modelValue"]))
          : (_openBlock(), _createBlock(_component_v_btn, {
              key: 1,
              width: "32px",
              height: "32px",
              icon: "",
              variant: "text",
              class: "bg-surface-variant",
              color: _ctx.icon?.buttonColor ?? 'primary'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  icon: _ctx.icon?.name ?? 'mdi-plus',
                  size: "24px",
                  color: _ctx.icon?.color ?? 'on-primary'
                }, null, 8, ["icon", "color"])
              ]),
              _: 1
            }, 8, ["color"]))
      ]),
      _createElementVNode("div", {
        class: "flex-grow-1",
        style: _normalizeStyle([{"display":"grid","grid-template-rows":"32px","align-items":"center"}, `grid-template-columns: 1fr repeat(${_ctx.columns - 1}, 0.5fr)`])
      }, [
        _renderSlot(_ctx.$slots, "default", {
          data: { item: _ctx.item, isHovering: isHovering.value }
        })
      ], 4)
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})