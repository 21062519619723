import {
  BASE_URL,
  type Report,
  type RequestDataCreateReport,
  type RequestDataSuggestion,
  type RequestReport,
  type SuggestionItem,
} from "@/api/resources/ReportsResource/nexusResource";
import { http, HttpResponse } from "msw";
import {
  reportsDB,
  reportsEmbedDB,
  suggestionReportNamesDB,
  suggestionReportOwnersDB,
} from "../reportDB";
import { useLocalStorage } from "@/utils/localstorage/localstorage";

const { getStorage } = useLocalStorage();

export const reportHandlers = [
  http.post(BASE_URL + "/nexus/powerbi/reports/search", async ({ request }) => {
    const limitReport = 5;
    const requestData = (await request.json()) as RequestReport;
    const page = requestData.pagging?.page ?? 1;
    const limit = requestData.pagging?.limit ?? 10;

    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;

    let filtered: Report[] = reportsDB.results;

    if (requestData.filters?.updatedAtFrom && requestData.filters.updatedAtTo) {
      const fromDate = new Date(requestData.filters.updatedAtFrom);
      const toDate = new Date(requestData.filters.updatedAtTo);

      filtered = filtered.filter((reporter) => {
        const reportDate = new Date(reporter.updatedAt);

        reportDate.setHours(0, 0, 0, 0);
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        return reportDate >= fromDate && reportDate <= toDate;
      });
    }

    if (requestData.filters?.ids && requestData.filters?.ids.length > 0) {
      filtered = filtered.filter((report) => {
        return requestData.filters?.ids?.includes(report.id) ?? false;
      });
    }

    if (
      requestData.filters?.ownerIds &&
      requestData.filters?.ownerIds?.length > 0
    ) {
      filtered = filtered.filter((report) => {
        return requestData.filters?.ownerIds?.includes(report.id) ?? false;
      });
    }

    const reportPage = filtered.slice(startIndex, endIndex);

    const response = {
      results: reportPage,
      canAddNewReport: reportsDB.results.length < limitReport,
    };

    return HttpResponse.json(response);
  }),

  http.delete(BASE_URL + "/nexus/powerbi/reports/:id", async ({ params }) => {
    const id = params.id;
    reportsDB.results = reportsDB.results.filter((report) => report.id !== id);
    reportsEmbedDB.splice(
      0,
      reportsEmbedDB.length,
      ...reportsEmbedDB.filter((report) => report.id !== id)
    );

    return HttpResponse.json();
  }),

  http.post(BASE_URL + "/nexus/suggestions", async ({ request }) => {
    const requestData = (await request.json()) as RequestDataSuggestion;
    const offset = requestData.limit ?? 10;
    const requestValue = requestData.value.toLowerCase();

    function generateSuggestion(store: SuggestionItem[]): HttpResponse {
      const results: SuggestionItem[] = store
        .filter((suggestion) => suggestion.value.toLowerCase().includes(requestValue))
        .slice(0, offset)
        .map((suggestion) => ({ id: suggestion.id, value: suggestion.value, subValue: suggestion.subValue }));
      return HttpResponse.json({ results });
    }

    if (requestData.type === 1)
      return generateSuggestion(suggestionReportNamesDB);

    if (requestData.type === 2)
      return generateSuggestion(suggestionReportOwnersDB)

    return HttpResponse.json({});
  }),

  http.post(BASE_URL + "/nexus/powerbi/reports", async ({ request }) => {
    const requestData = (await request.json()) as RequestDataCreateReport;
    const newReport: Report = {
      id: Math.random().toString(36).substring(7),
      name: requestData.name,
      ownerName: "Owner Demo",
      updatedAt: new Date(),
    };

    reportsDB.results.unshift(newReport);
    reportsEmbedDB.unshift({
      id: newReport.id,
      result: {
        ownerName: newReport.ownerName,
        reportName: newReport.name,
        updatedAt: newReport.updatedAt,
        embedToken: {
          token: getStorage("token-bi") || "",
          tokenId: "81e47435-e540-4735-b5a1-35e81620853b",
          expiration: "2024-09-05T20:18:14Z",
        },
        reportId: "1711d2b9-6375-40bd-b273-22e64d9b4fa7",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=1711d2b9-6375-40bd-b273-22e64d9b4fa7&groupId=6445d8c6-cd38-4f63-b366-5b7b85e46235&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
        datasetIds: ["03891d64-e0d3-4b5c-b71d-5d79411b7bb1"],
      },
    });

    return HttpResponse.json({ reportId: newReport.id });
  }),

  http.get(BASE_URL + "/nexus/powerbi/basereports", () => {
    const limitReport = 5;
    return HttpResponse.json({
      canAddNewReport: reportsDB.results.length < limitReport,
      results: [
        {
          baseReportId: "1",
          tag: "Base report",
        },
        {
          baseReportId: "2",
          tag: "Blank report",
        },
      ],
    });
  }),

  http.get(BASE_URL + "/nexus/powerbi/reports/:id", async ({ params }) => {
    const requestData = params as { id: string };
    const reportFinded = reportsEmbedDB.find(
      (report) => report.id === requestData.id
    );
    return HttpResponse.json(reportFinded?.result);
  }),

  http.patch(BASE_URL + "/nexus/powerbi/reports", async ({ params }) => {
    const requestData = params as { reportId: string; name: string };
    const reportFindedIndex = reportsEmbedDB.findIndex(
      (report) => report.id === requestData.reportId
    );
    const reportFinded = reportsEmbedDB[reportFindedIndex];

    if (reportFinded) {
      reportFinded.result.reportName = requestData.name;
      reportFinded.result.updatedAt = new Date();

      reportsEmbedDB[reportFindedIndex] = reportFinded;
    }

    return new HttpResponse("", {
      status: 200,
      headers: {
        "Content-Type": "text/plain",
      },
    });
  }),

  http.get(BASE_URL + "/one/users/apps", async () => {
    return HttpResponse.json({
      results: ["One", "Shield", "Nexus"],
    });
  }),
];
