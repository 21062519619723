<template>
  <div
    :data-testid="generateTestId(CustomHeroElements.Content)"
    id="hero"
    :style="{
      backgroundImage: mdAndUp ? `url('${srcImage}')` : `url('${srcImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionY: '0px',
      padding: `${mdAndUp ? '1.5rem' : '0.75rem'}`,
      height,
      width,
    }"
    class="title h-100 w-100 position-relative"
  >
    <h1
      class="on-surface"
      :style="{
        fontSize: mdAndUp ? fontSizeLarge : fontSizeSmall,
        lineHeight: mdAndUp ? lineHeightLarge : lineHeightSmall,
        fontWeight: mdAndUp ? fontWeightLarge : fontWeightSmall,
      }"
    >
      {{ t(label || "") }}
    </h1>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";
import { tokens } from "@clearsale/tokens-ds";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { mdAndUp } = useDisplay();

const fontSizeLarge = tokens.typography["typescale-display-large-size"];
const fontSizeSmall = tokens.typography["typescale-display-small-size"];

const lineHeightLarge =
  tokens.typography["typescale-display-large-line-height"];
const lineHeightSmall =
  tokens.typography["typescale-display-small-line-height"];

const fontWeightLarge = tokens.typography["typescale-display-large-weight"];
const fontWeightSmall = tokens.typography["typescale-display-small-weight"];

defineProps<{
  label?: string;
  srcImage?: string;
  width?: string;
  height?: string;
}>();
</script>

<style scoped lang="scss">
.title {
  width: 100%;
  box-sizing: border-box;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  border-radius: var(--mdui-shape-corner-extra-large);

  display: flex;

  justify-content: space-between;
  align-items: flex-end;

  & h1 {
    margin: 0;
    font-size: var(--mdui-typescale-display-large-size);
    font-weight: var(--mdui-typescale-display-large-weight);
  }

  & .status-cards {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    gap: 8px;

    & h2 {
      font-size: var(--mdui-typescale-display-small-size);
      font-weight: var(--mdui-typescale-display-small-weight);
    }

    .v-card-text {
      padding: 0;
      flex: 0;

      & > span {
        font-size: var(--mdui-typescale-body-medium-size);

        & v-icon {
          font-size: 0.5rem;
        }
      }

      & > p {
        margin: 0;
        font-size: var(--mdui-typescale-headline-medium-size);
        font-weight: var(--mdui-typescale-headline-medium-weight);
        color: black;

        & span {
          font-size: var(--mdui-typescale-title-small-size);
          font-weight: var(--mdui-typescale-title-small-weight);
        }
      }
    }
  }
}
</style>
