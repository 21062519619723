import Hammer from 'hammerjs';

export type SWIPE_TYPE = 'LEFT' | 'RIGHT' | 'BOTH';

interface SwipeOptions {
  leftElement: HTMLElement | null;
  rightElement: HTMLElement | null;
  type: SWIPE_TYPE;
}

export function swipeUtility(element: HTMLElement, { leftElement = null, rightElement = null, type = 'LEFT' }: SwipeOptions) {
  let leftElementWidth: number | null = null;
  let rightElementWidth: number | null = null;
  let isDragging = false;
  let startTransformX = 0;

  const hammer = new Hammer(element);

  if (leftElement) leftElementWidth = leftElement.getBoundingClientRect().width;

  if (rightElement) rightElementWidth = rightElement.getBoundingClientRect().width;

  function startDrag() {
    isDragging = true;

    // the value here could be "none" or "matrix(a, b, c, d, tx, ty)"
    const initialTransform = getComputedStyle(element).transform;
    const isSettedHammerTransform = initialTransform.startsWith('matrix') || initialTransform !== 'none';

    startTransformX = isSettedHammerTransform ? parseInt(initialTransform.split(',')[4]) : 0;
  }

  function duringDrag(e: any) {
    element.style.transition = 'none';
    if ((isDragging && leftElementWidth) || rightElementWidth) {
      const deltaX = e.deltaX;
      const newTransformX = startTransformX + deltaX;

      if (leftElementWidth && rightElementWidth)
        switch (type) {
          case 'BOTH':
            if (newTransformX < -leftElementWidth) {
              element.style.transform = `translateX(${-leftElementWidth}px)`;
            } else if (newTransformX > rightElementWidth) {
              element.style.transform = `translateX(${rightElementWidth}px)`;
            } else {
              element.style.transform = `translateX(${newTransformX}px)`;
            }
            break;

          case 'LEFT':
            if (newTransformX < 0 && newTransformX > -leftElementWidth) {
              element.style.transform = `translateX(${newTransformX}px)`;
            }
            break;

          case 'RIGHT':
            if (newTransformX > 0 && newTransformX < rightElementWidth) {
              element.style.transform = `translateX(${newTransformX}px)`;
            }
            break;
        }
    }
  }

  function endDrag() {
    isDragging = false;
    element.style.transition = 'transform .1s';

    const currentTransformX = parseInt(getComputedStyle(element).transform.split(',')[4]);
    const center = 0;

    if (leftElementWidth && rightElementWidth)
      if (currentTransformX < -leftElementWidth / 3) {
        element.style.transform = `translateX(${-leftElementWidth}px)`;
      } else if (currentTransformX > rightElementWidth / 3) {
        element.style.transform = `translateX(${rightElementWidth}px)`;
      } else {
        element.style.transform = `translateX(${center}px)`;
      }
  }

  function onLoadEvents(hammer: HammerManager) {
    if (hammer) {
      hammer.on('panstart', startDrag);

      hammer.on('panmove', duringDrag);

      hammer.on('panend', endDrag);
    }
  }

  return {
    hammer,
    onLoadEvents,
  };
}
