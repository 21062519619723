import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-4" }

import { tokens } from "@clearsale/tokens-ds";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemIMG',
  props: {
    img: {},
    title: {},
    subtitle: {},
    height: {},
    width: {},
    active: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any) {

const fontSize = tokens.typography["typescale-label-large-size"];





return (_ctx: any,_cache: any) => {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (_openBlock(), _createBlock(_component_v_hover, null, {
    default: _withCtx(({ isHovering, props }) => [
      _createVNode(_component_v_card, _mergeProps({
        "data-testid": "card",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
        elevation: "0"
      }, props, {
        class: [[
          isHovering && 'bg-cs_surface_container_highest',
          _ctx.active && 'bg-cs_surface_container',
          !isHovering && !_ctx.active && 'bg-cs_background',
        ], "d-flex ga-4 rounded-xl ga-4 pa-2 cursor-pointer"]
      }), {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_v_img, {
              class: "rounded-xl mx-auto",
              "lazy-src": _ctx.img?.src ?? 'https://picsum.photos/id/237/120/80',
              src: _ctx.img?.src ?? 'https://picsum.photos/id/237/120/80',
              alt: _ctx.img?.alt ?? 'picsum',
              width: _ctx.width ?? '120px',
              height: _ctx.height ?? '80px'
            }, null, 8, ["lazy-src", "src", "alt", "width", "height"])
          ]),
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createElementVNode("h6", {
                class: "text-cs_on_surface_variant",
                style: _normalizeStyle(`font-size: ${_unref(fontSize)}`)
              }, _toDisplayString(_ctx.title), 5),
              _createElementVNode("p", {
                class: "text-cs_on_surface_variant",
                style: _normalizeStyle(`font-size: ${_unref(fontSize)}`)
              }, _toDisplayString(_ctx.subtitle), 5)
            ])
          ])
        ]),
        _: 2
      }, 1040, ["class"])
    ]),
    _: 3
  }))
}
}

})