<template>
  <div
    v-if="loadApp"
    style="background-color: white; display: flex; flex-direction: column"
  >
    <v-theme-provider>
      <div
        class="bg-cs_background"
        style="
          flex: 1 0;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          min-height: calc(100vh - 80px);
        "
      >
        <div class="w-100 mx-auto">
          <RouterView />
        </div>
        <FooterLogo v-if="footerView" />
      </div>
    </v-theme-provider>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, onUnmounted, computed, ref } from "vue";
import { useRouter } from "vue-router";

import "@/plugins/gsap";
import type {
  LANGUAGE_CHANGED_PAYLOAD,
  THEME_CHANGED_PAYLOAD,
} from "@clearsale/one-lib-events";
import { customEvents } from "@clearsale/one-lib-events";

import FooterLogo from "@/components/shared/Footer/FooterLogo.vue";
import { useReportsStore } from "@/store/ReportsStore";

import { useTheme } from "vuetify";

import imgNexus from "@/assets/logo_nexus.svg";
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from "@unhead/vue";
import { i18n } from "./plugins/i18n";
import { auth } from "./services/auth";
import { dispatchConfigEvent } from "./utils/loadTopMenu";
import { enableMocking } from "./api/mocks/browser";

const headInfo = useHead({
  title: "Nexus",
  link: [{ rel: "icon", href: imgNexus, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const { $patch, $state } = useReportsStore();

const router = useRouter();

const footerView = computed(() => {
  if (
    router.currentRoute.value.name === "NexusEditReportView" &&
    $state.isLandScape
  ) {
    return false;
  } else if (
    router.currentRoute.value.name === "NexusReportView" &&
    $state.isLandScape
  ) {
    return false;
  } else {
    return true;
  }
});

const activeSSO = process.env.VUE_APP_ENV !== "development";

const loadApp = ref(false);

const updateOrientation = () => {
  const angleDevice = screen.orientation.angle;

  if (angleDevice === 0) {
    $patch({ isLandScape: false });
    return;
  }

  $patch({ isLandScape: true });
};
const theme = useTheme();

function changeThemeOnEvent(event: CustomEvent<THEME_CHANGED_PAYLOAD>) {
  const themeName = event.detail;
  theme.global.name.value = themeName;
}

function changeLanguageOnEvent(event: CustomEvent<LANGUAGE_CHANGED_PAYLOAD>) {
  const language = event.detail;
  i18n.global.locale.value = language;
}

onBeforeMount(() => {
  const storeThemeName = localStorage.getItem("theme");
  if (storeThemeName && storeThemeName?.length > 0) {
    theme.global.name.value = storeThemeName;
  }

  const storeLanguage = localStorage.getItem("language");
  if (storeLanguage && storeLanguage?.length > 0) {
    i18n.global.locale.value = storeLanguage;
  }
});

onMounted(async () => {
  customEvents.themeEvents.listener(changeThemeOnEvent);
  customEvents.languageEvent.listener(changeLanguageOnEvent);

  screen.orientation.addEventListener("change", updateOrientation);

  if (activeSSO) {
    await auth.execute(() => {
      loadApp.value = true;
      dispatchConfigEvent();
    });
    return;
  }

  loadApp.value = true;
});

onUnmounted(() => {
  screen.orientation.removeEventListener("change", updateOrientation);
  customEvents.languageEvent.unsubscribe();
  customEvents.themeEvents.unsubscribe();

  headInfo.dispose();
});
</script>
