import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-inverse-on-surface" }

import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'SnackbarClose',
  props: {
    "modelValue": { default: false },
    "modelModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["close"], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const model = _useModel(__props, "modelValue");
const emits = __emit;

function handleCloseSnackbar() {
  emits("close");
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    color: "inverse-surface"
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "inverse-on-surface",
        variant: "text",
        onClick: handleCloseSnackbar,
        icon: "mdi-close"
      })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(t)(_unref(label).ReportsPage.FilteLimitReached.title)), 1)
      ])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}
}

})