import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import ComponentPreview from '../views/ComponentPreview.vue'
import NexusView from '@/views/ReportsView/NexusView.vue'
import NexusNewReportView from '@/views/ReportsView/NexusNewReportView.vue'
import NexusEditReportView from '@/views/ReportsView/NexusEditReportView.vue'
import NexusReportView from '@/views/ReportsView/NexusReportView.vue'
import { customEvents } from '@clearsale/one-lib-events'



export const routeNames = {
  reports: 'NexusView',
  newReport: 'NexusNewReportView',
  preview: 'Preview',
  editReport: 'NexusEditReportView',
  viewReport: 'NexusReportView'
}
export const hidableHeaderRoutes: string[] = [routeNames.editReport, routeNames.viewReport];

// const activeSSO = process.env.VUE_APP_ENV !== "development"


export const routes: Array<RouteRecordRaw> = [
  {
    path: '/nexus',
    component: { template: '', beforeCreate() { this.$router.push({ name: routeNames.reports }) } }
  },
  {
    path: '/nexus/reports',
    name: routeNames.reports,
    component: NexusView
  },
  {
    path: '/nexus/reports/new',
    name: routeNames.newReport,
    component: NexusNewReportView
  },

  {
    path: '/nexus/reports/edit/:id',
    name: routeNames.editReport,
    component: NexusEditReportView
  },
  {
    path: '/nexus/reports/view/:id',
    name: routeNames.viewReport,
    component: NexusReportView
  },
  {
    path: '/nexus/preview',
    name: routeNames.preview,
    component: ComponentPreview
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
