import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


import {
	type WidthScrollContainerEmits,
	type WidthScrollContainerProps,
	useWidthScrollContainer,
} from "./hooks/useWidthScrollContainer";


export default /*@__PURE__*/_defineComponent({
  ...{
	inheritAttrs: false,
},
  __name: 'WidthScrollContainer',
  props: {
    width: {},
    height: {},
    pinedStyle: { type: [Boolean, null, String, Object, Array] }
  },
  emits: ["scrollOnTop"],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "146718df": (_unref(scrollToWidth)),
  "5ead092e": (_unref(scrollToHeight))
}))



const props = __props;
const emits = __emit;

const {
	scrollToWidth,
	scrollToHeight,
	pinnedContainerClass,
	slotContainerClass,
	refPinnedContainer,
	refSlotContainer,
} = useWidthScrollContainer(emits, props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "refPinnedContainer",
    ref: refPinnedContainer,
    style: _normalizeStyle([_ctx.pinedStyle, { top: '0px !important' }]),
    class: _normalizeClass([_unref(pinnedContainerClass), ""])
  }, [
    _createElementVNode("div", _mergeProps({
      ref_key: "refSlotContainer",
      ref: refSlotContainer
    }, _ctx.$attrs, { class: _unref(slotContainerClass) }), [
      _renderSlot(_ctx.$slots, "default")
    ], 16)
  ], 6))
}
}

})