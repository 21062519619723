import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex justify-center align-center py-2 bottom-0 w-100 bg-cs_background",
  style: {"position":"relative","bottom":"0"}
}
const _hoisted_2 = ["src", "alt"]

import { ref } from "vue";

import imgClearsale from "@/assets/logo_mobile.png";


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterLogo',
  setup(__props) {

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      style: {"width":"24px"},
      src: imgClearsaleOptions.value.src,
      alt: imgClearsaleOptions.value.alt
    }, null, 8, _hoisted_2)
  ]))
}
}

})