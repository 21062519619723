<template>
  <div
    class="d-flex justify-center align-center py-2 bottom-0 w-100 bg-cs_background"
    style="position: relative; bottom: 0"
  >
    <img
      style="width: 24px"
      :src="imgClearsaleOptions.src"
      :alt="imgClearsaleOptions.alt"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";

import imgClearsale from "@/assets/logo_mobile.png";

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});
</script>
<style lang="scss" scoped></style>
