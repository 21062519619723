export const label = {
  AddNewPage: {
    title: "Añadir nuevo",
    information: "Información",
    name: "Nombre",
    Template: {
      title: "Plantilla",
      BaseReport: {
        title: "Informe base",
        content: "Comienza con nuestra plantilla",
      },
      BlankReport: {
        title: "Informe en blanco",
        content: "Crea tus propios visuales",
      },
    },
    Save: {
      title: "Guardar",
    },
  },
  Modals: {
    DiscardChanges: {
      title: "Descartar cambios",
      content:
        "Tienes cambios no guardados. ¿Estás seguro de que deseas descartarlos?",
      cancel: "Cancelar",
      discard: "Descartar",
    },
    DeleteFile: {
      title: "Eliminar archivo",
      content:
        "¿Estás seguro de que deseas eliminar este archivo permanentemente?",
      cancel: "Cancelar",
      delete: "Eliminar",
    },
  },
  EditViewReportPage: {
    RotateDevice: {
      title: "Gira tu dispositivo para pantalla completa",
    },
    Edit: {
      title: "Editar",
    },
    Save: {
      title: "Guardar",
    },
  },
  TopMenu: {
    Search: "Buscar en ClearSale",
  },
  UserMenu: {
    language: {
      title: "Idioma",
      english: "English",
      portuguese: "Português",
      spanish: "Español",
    },
    timezone: "Zona horaria",
    theme: {
      title: "Tema",
      dark: "Oscuro",
      light: "Claro",
    },
    signout: "Desconectar",
  },
  ReportsPage: {
    title: "Nexus",
    AddReportDesktop: {
      title: "Haz clic aquí para crear tu primer tablero",
    },
    AddReportMobile: {
      title: "Toca aquí para crear tu primer tablero",
    },
    AddReport: {
      title: "Añadir",
    },
    ButtonFilter: {
      Name: {
        title: "Nombre",
      },
      Owner: {
        title: "Propietario",
      },
      DateModified: {
        title: "Fecha de modificación",
      },
    },
    FilteLimitReached: {
      title:
        "Límite de archivos alcanzado. Considera eliminar para añadir más.",
    },
  },
};
