export const label = {
  AddNewPage: {
    title: "Add new",
    information: "Information",
    name: "Name",
    Template: {
      title: "Template",
      BaseReport: {
        title: "Base report",
        content: "Get started with our template"
      },
      BlankReport: {
        title: "Blank report",
        content: "Build your own visuals"
      }
    },
    Save: {
      title: "Save"
    }
  },
  Modals: {
    DiscardChanges: {
      title: "Discard changes",
      content: "You have unsaved changes. Are you sure you want to discard them?",
      cancel: "Cancel",
      discard: "Discard"
    },
    DeleteFile: {
      title: "Delete file",
      content: "Are you sure you want to permanently delete this file?",
      cancel: "Cancel",
      delete: "Delete"
    }
  },
  EditViewReportPage: {
    RotateDevice: {
      title: "Rotate your device for full screen view"
    },
    Edit: {
      title: "Edit"
    },
    Save: {
      title: "Save"
    }
  },
  TopMenu: {
    Search: "Search on ClearSale"
  },
  UserMenu: {
    language: {
      title: "Language",
      english: "English",
      portuguese: "Português",
      spanish: "Español"
    },
    timezone: "Timezone",
    theme: {
      title: "Theme",
      dark: "Dark",
      light: "Light"
    },
    signout: "Sign Out"
  },
  ReportsPage: {
    title: "Nexus",
    AddReportDesktop: {
      title: "Click here to create your first dashboard"
    },
    AddReportMobile: {
      title: "Tap here to create your first dashboard"
    },
    AddReport: {
      title: "Add"
    },
    ButtonFilter: {
      Name: {
        title: "Name"
      },
      Owner: {
        title: "Owner"
      },
      DateModified: {
        title: "Date modified"
      }
    },
    FilteLimitReached: {
      title: "File limit reached. Consider deleting to add more."
    }
  }
}