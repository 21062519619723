import { defineStore } from "pinia";
import { Report } from "@/api/resources/ReportsResource/nexusResource";

export interface ReportsStore extends Report {
  selected: boolean
}

export interface ReportFilters {
  ids: string[],
  ownerIds: string[],
  names: string[],
  owners: string[],
  updatedAtFrom: Date | null,
  updatedAtTo: Date | null
}

export const useReportsStore = defineStore('store-reports', {
  state: () => ({
    isLandScape: window.matchMedia("(orientation: landscape)").matches,
    reports: [

    ] as ReportsStore[],
    filters: {
      ids: [] as string[],
      names: [] as string[],
      owners: [] as string[],
      ownerIds: [] as string[],
      updatedAtFrom: null,
      updatedAtTo: null,
    } as ReportFilters,
    pagging: {
      page: 1,
      limit: 5
    }
  }),
  actions: {
    addReport(report: Report) {
      this.reports.push({ ...report, selected: false })
    },
    removeReport(report: Report) {
      const index = this.reports.findIndex((findReport) => findReport.id === report.id)
      this.reports.splice(index, 1)
    },
    toggleReport(report: Report) {
      const index = this.reports.findIndex((findReport) => findReport.id === report.id)
      this.reports[index].selected = !this.reports[index].selected
    },
    clearReports() {
      this.reports = []
    },
    updateAllReports(reports: Report[]) {
      this.reports = reports.map((report) => {
        return {
          ...report,
          selected: false
        }
      })
    }

  },
})


