<template>
  <section ref="container"></section>
</template>

<script setup lang="ts">
import { ResponseReportEdit } from "@/api/resources/ReportsResource/nexusResource";
import { factories, models, service, Embed } from "powerbi-client";
import { Ref, onMounted, ref } from "vue";
const container = ref<HTMLDivElement>() as Ref<HTMLDivElement>;
const props = defineProps<{
  report: ResponseReportEdit;
  mode: ViewMode;
  reportId: string;
}>();
const emit = defineEmits(["loaded", "click"]);

enum ViewMode {
  View = 0,
  Edit = 1,
}

onMounted(async () => {
  const powerBIConfiguration = {
    settings: {
      useCustomSaveAsDialog: true,
      filterPaneEnabled: true,
      navContentPaneEnabled: true,
      layoutType: models.LayoutType.Custom,
      //layoutType: models.LayoutType.MobilePortrait,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage,
      },
    },
    viewMode:
      props.mode === ViewMode.Edit
        ? models.ViewMode.Edit
        : models.ViewMode.View,
    permissions: models.Permissions.ReadWrite,
    tokenType: models.TokenType.Embed,
    datasetId: props.report.datasetIds[0],
    accessToken: props.report.embedToken.token,
    embedUrl: props.report.embedUrl,
    id: props.reportId,
    type: "report",
  };
  const powerbi = new service.Service(
    factories.hpmFactory,
    factories.wpmpFactory,
    factories.routerFactory
  );

  powerbi.bootstrap(container.value, powerBIConfiguration);
  let embed = powerbi.embed(container.value, powerBIConfiguration) as Embed;

  embed.on("loaded", () => {
    emit("loaded", embed);
  });

  embed.on("buttonClicked", () => {
    emit("click");
  });
});
</script>

<style scoped>
section {
  height: 100%;
}
</style>
