import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "text-subtitle text-cs_on_surface",
  style: {"font-weight":"400"}
}
const _hoisted_2 = {
  class: "mt-4 text-subtitle-2 text-cs_on_surface_variant",
  style: {"line-height":"1.25rem"}
}
const _hoisted_3 = { class: "mt-6 d-flex justify-end" }

import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'DiscardModal',
  emits: ["cancel", "confirm"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const emits = __emit;

function handleConfirm() {
  emits("confirm");
}
function handleCancel() {
  emits("cancel");
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    "max-width": "312",
    height: "200",
    class: "rounded-xl pa-4 bg-cs_surface_container_high",
    elevation: "1"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(t)(_unref(label).Modals.DiscardChanges.title)), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)(_unref(label).Modals.DiscardChanges.content)), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_btn, {
          height: "40px",
          text: _unref(t)(_unref(label).Modals.DiscardChanges.cancel),
          variant: "text",
          class: "text-none text-cs_on_surface_variant",
          onClick: handleCancel
        }, null, 8, ["text"]),
        _createVNode(_component_v_btn, {
          height: "40px",
          text: _unref(t)(_unref(label).Modals.DiscardChanges.discard),
          variant: "text",
          class: "text-none text-cs_error",
          onClick: handleConfirm
        }, null, 8, ["text"])
      ])
    ]),
    _: 1
  }))
}
}

})