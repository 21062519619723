import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-space-between align-center"
}
const _hoisted_2 = { class: "d-flex align-center ga-4" }
const _hoisted_3 = { style: {"max-width":"210px"} }
const _hoisted_4 = { class: "d-flex ga-4" }
const _hoisted_5 = { class: "d-flex ga-2 text-cs_tertiary" }

import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useDisplay, useTheme } from "vuetify";
import { useReportsStore } from "@/store/ReportsStore";
import {
  deleteReportAPI,
  getReportEmbed,
  ResponseReportEdit,
} from "@/api/resources/ReportsResource/nexusResource";
import SnackbarClose from "@/components/nexus/SnackbarClose/SnackbarClose.vue";
import ReportBI from "@/components/nexus/ReportBI/ReportBI.vue";
import DeleteModal from "@/components/nexus/DeleteModal/DeleteModal.vue";
import { useLocalStorage } from "@/utils/localstorage/localstorage";
import { routeNames } from "@/router";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";
import { customEvents } from "@clearsale/one-lib-events";

enum ViewMode {
  View = 0,
  Edit = 1,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NexusReportView',
  setup(__props) {

const { t } = useI18n();
const { $state, $patch } = useReportsStore();
const { name: themeName } = useTheme();

const { mdAndUp, lgAndUp } = useDisplay();
const router = useRouter();
const route = useRoute();
const { getStorage } = useLocalStorage();

const confirmDeleteReport = ref(false);
const rotateSmartPhoneSnackbar = ref(false);

const reportEdit = ref<ResponseReportEdit | undefined>(undefined);
const reportIdLoad =
  computed(() => route.params.id as string) &&
  process.env.VUE_APP_ENV !== "development"
    ? (route.params.id as string)
    : getStorage("report-id") ?? "1711d2b9-6375-40bd-b273-22e64d9b4fa7";

function handleRedirectToEdit() {
  router.push({
    name: routeNames.editReport,
    params: { id: route.params.id },
  });
}

function handleDeleteReport() {
  if (route.params.id as string) {
    deleteReportAPI(route.params.id as string).then(() => {
      router.push({
        name: routeNames.reports,
      });
    });
  }
}

onMounted(() => {
  rotateSmartPhoneSnackbar.value = true;
});

onMounted(() => {
  if ($state.isLandScape) {
    customEvents.menuEvent.displayToggled.dispatch(false);
  }
  if (route.params.id as string) {
    getReportEmbed(route.params.id as string).then((response) => {
      reportEdit.value = response;
    });
    return;
  }
  router.push({
    name: routeNames.reports,
  });
});

watch(
  () => $state.isLandScape,
  () => {
    customEvents.menuEvent.displayToggled.dispatch(
      $state.isLandScape ? false : true
    );
  }
);

onUnmounted(() => {
  customEvents.menuEvent.displayToggled.dispatch(true);
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (reportEdit.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["d-flex flex-column ga-4 mx-auto", !_unref($state).isLandScape ? 'px-4' : 'px-0']),
          style: _normalizeStyle({ maxWidth: _unref(lgAndUp) ? '86vw' : '1040px' })
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-full bg-cs_secondary_container", !_unref($state).isLandScape ? 'rounded-xl' : '']),
            style: _normalizeStyle({
        height: !_unref($state).isLandScape
          ? 'calc(100vh - 80px - 60px - 48px)'
          : '100vh',
        overflow: 'hidden',
      })
          }, [
            (reportEdit.value.embedToken.token)
              ? (_openBlock(), _createBlock(ReportBI, {
                  key: 0,
                  mode: ViewMode.View,
                  report: reportEdit.value,
                  "report-id": _unref(reportIdLoad)
                }, null, 8, ["mode", "report", "report-id"]))
              : _createCommentVNode("", true)
          ], 6),
          (!_unref($state).isLandScape)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_btn, {
                    color: "cs_surface_container",
                    icon: "mdi-arrow-left",
                    class: "elevation-0",
                    size: "40px",
                    onClick: _cache[0] || (_cache[0] = 
            () => {
              _unref(router).back();
            }
          ),
                    "data-testid": "back-button"
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, _toDisplayString(reportEdit.value.reportName), 1),
                    _createElementVNode("div", null, _toDisplayString(reportEdit.value.ownerName), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_v_btn, {
                    height: "40px",
                    color: 
            _unref(themeName) === 'dark'
              ? 'cs_ref_palette_information30'
              : 'cs_ref_palette_information95'
          ,
                    class: "elevation-0",
                    onClick: handleRedirectToEdit
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_v_icon, { icon: "mdi-pencil" }),
                        _createTextVNode(" " + _toDisplayString(_unref(t)(_unref(label).EditViewReportPage.Edit.title)), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["color"]),
                  _createVNode(_component_v_btn, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (confirmDeleteReport.value = true)),
                    icon: "mdi-trash-can-outline",
                    size: "40px",
                    class: "elevation-0",
                    color: 
            _unref(themeName) === 'dark'
              ? 'cs_ref_palette_information30'
              : 'cs_ref_palette_information95'
          
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: "mdi-pencil",
                        class: "mdi-trash-can-outline text-cs_tertiary"
                      })
                    ]),
                    _: 1
                  }, 8, ["color"])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 6))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: confirmDeleteReport.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((confirmDeleteReport).value = $event)),
      width: "auto",
      scrim: "black"
    }, {
      default: _withCtx(() => [
        (confirmDeleteReport.value)
          ? (_openBlock(), _createBlock(DeleteModal, {
              key: 0,
              onCancel: _cache[2] || (_cache[2] = ($event: any) => (confirmDeleteReport.value = false)),
              onConfirm: _cache[3] || (_cache[3] = ($event: any) => (handleDeleteReport()))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (!_unref(mdAndUp) && !_unref($state).isLandScape)
      ? (_openBlock(), _createBlock(SnackbarClose, {
          key: 1,
          modelValue: rotateSmartPhoneSnackbar.value,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((rotateSmartPhoneSnackbar).value = $event)),
          onClose: _cache[6] || (_cache[6] = ($event: any) => (rotateSmartPhoneSnackbar.value = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_unref(t)(_unref(label).EditViewReportPage.RotateDevice.title)), 1)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})