import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"background-color":"white","display":"flex","flex-direction":"column"}
}
const _hoisted_2 = {
  class: "bg-cs_background",
  style: {"flex":"1 0","display":"flex","justify-content":"space-between","flex-direction":"column","min-height":"calc(100vh - 80px)"}
}
const _hoisted_3 = { class: "w-100 mx-auto" }

import { onBeforeMount, onMounted, onUnmounted, computed, ref } from "vue";
import { useRouter } from "vue-router";

import "@/plugins/gsap";
import type {
  LANGUAGE_CHANGED_PAYLOAD,
  THEME_CHANGED_PAYLOAD,
} from "@clearsale/one-lib-events";
import { customEvents } from "@clearsale/one-lib-events";

import FooterLogo from "@/components/shared/Footer/FooterLogo.vue";
import { useReportsStore } from "@/store/ReportsStore";

import { useTheme } from "vuetify";

import imgNexus from "@/assets/logo_nexus.svg";
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from "@unhead/vue";
import { i18n } from "./plugins/i18n";
import { auth } from "./services/auth";
import { dispatchConfigEvent } from "./utils/loadTopMenu";
import { enableMocking } from "./api/mocks/browser";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const headInfo = useHead({
  title: "Nexus",
  link: [{ rel: "icon", href: imgNexus, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const { $patch, $state } = useReportsStore();

const router = useRouter();

const footerView = computed(() => {
  if (
    router.currentRoute.value.name === "NexusEditReportView" &&
    $state.isLandScape
  ) {
    return false;
  } else if (
    router.currentRoute.value.name === "NexusReportView" &&
    $state.isLandScape
  ) {
    return false;
  } else {
    return true;
  }
});

const activeSSO = process.env.VUE_APP_ENV !== "development";

const loadApp = ref(false);

const updateOrientation = () => {
  const angleDevice = screen.orientation.angle;

  if (angleDevice === 0) {
    $patch({ isLandScape: false });
    return;
  }

  $patch({ isLandScape: true });
};
const theme = useTheme();

function changeThemeOnEvent(event: CustomEvent<THEME_CHANGED_PAYLOAD>) {
  const themeName = event.detail;
  theme.global.name.value = themeName;
}

function changeLanguageOnEvent(event: CustomEvent<LANGUAGE_CHANGED_PAYLOAD>) {
  const language = event.detail;
  i18n.global.locale.value = language;
}

onBeforeMount(() => {
  const storeThemeName = localStorage.getItem("theme");
  if (storeThemeName && storeThemeName?.length > 0) {
    theme.global.name.value = storeThemeName;
  }

  const storeLanguage = localStorage.getItem("language");
  if (storeLanguage && storeLanguage?.length > 0) {
    i18n.global.locale.value = storeLanguage;
  }
});

onMounted(async () => {
  customEvents.themeEvents.listener(changeThemeOnEvent);
  customEvents.languageEvent.listener(changeLanguageOnEvent);

  screen.orientation.addEventListener("change", updateOrientation);

  if (activeSSO) {
    await auth.execute(() => {
      loadApp.value = true;
      dispatchConfigEvent();
    });
    return;
  }

  loadApp.value = true;
});

onUnmounted(() => {
  screen.orientation.removeEventListener("change", updateOrientation);
  customEvents.languageEvent.unsubscribe();
  customEvents.themeEvents.unsubscribe();

  headInfo.dispose();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!

  return (loadApp.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_theme_provider, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_RouterView)
              ]),
              (footerView.value)
                ? (_openBlock(), _createBlock(FooterLogo, { key: 0 }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})