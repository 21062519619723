<template>
  <main
    class="d-flex flex-column flex-grow-1 mx-auto"
    style="max-width: 1040px"
  >
    <div
      v-triple-touch="handleTripleTouch"
      :class="mdAndUp || 'px-4'"
      :style="{ height: mdAndUp ? '220px' : '120px' }"
    >
      <CustomHero :label="label.ReportsPage.title" :src-image="imgHero" />
    </div>
    <div
      class="d-flex justify-space-between mt-2 mb-4"
      :class="mdAndUp || 'px-4'"
    >
      <div class="w-100">
        <!--Mobile filter turned off for now
        <template v-if="!mdAndUp">
            <ContainerSwiper>
              <div class="d-flex flex-row ga-2">
                  <ButtonFilterNameMobile />
                  <ButtonFilterOwnerMobile />
                  <ButtonFilterDateMobile />
              </div>
            </ContainerSwiper>
        </template>-->
      </div>
      <AddButton
        :label="t(label.ReportsPage.AddReport.title)"
        @click="handleAddReport"
      ></AddButton>
    </div>

    <section
      v-if="!loading"
      class="d-flex justify-center flex-grow-1"
      :class="hasEmptyReports ? 'align-center' : ''"
    >
      <CardCircle
        v-if="hasEmptyReports"
        @click="
          () => {
            router.push({ name: routeNames.newReport });
          }
        "
      >
        <div class="d-flex flex-column justify-center align-center text-center">
          <v-btn icon width="40" height="40">
            <v-icon :color="className" size="30" icon="mdi-plus"></v-icon>
          </v-btn>
          <p class="mt-5">
            {{
              t(
                mdAndUp
                  ? label.ReportsPage.AddReportDesktop.title
                  : label.ReportsPage.AddReportMobile.title
              )
            }}
          </p>
        </div>
      </CardCircle>
      <div
        v-else
        ref="transactionListRef"
        style="flex: 1"
        class="d-flex flex-column ga-2"
        :class="mdAndUp || 'px-4'"
      >
        <CardItemReport
          :reports="reportStore.$state.reports"
          @delete="handleDeleteReport"
          @edit="handleEditReport"
          @select="handleSelectReport"
        ></CardItemReport>
      </div>
    </section>
  </main>

  <SnackbarClose
    v-model="messageLimitSnackbar"
    @close="messageLimitSnackbar = false"
  ></SnackbarClose>

  <DeveloperMode v-model:dialog="dialogDeveloperMode"></DeveloperMode>
</template>

<script setup lang="ts">
import { routeNames } from "@/router/index";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useDisplay, useTheme } from "vuetify";

import imgHeroLight from "@/assets/backgrounds/BG-nexus-light.jpg";
import imgHeroDark from "@/assets/backgrounds/BG-nexus-dark.jpg";

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import CardCircle from "@/components/nexus/CardCircle/CardCircle.vue";
import CardItemReport from "@/components/nexus/CardItem/CardItemReport.vue";
import AddButton from "@/components/shared/AddButton/AddButton.vue";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import DeveloperMode from "@/components/nexus/DeveloperMode/DeveloperMode.vue";

import {
  Report,
  RequestReport,
  deleteReportAPI,
  getReports,
} from "@/api/resources/ReportsResource/nexusResource";
import SnackbarClose from "@/components/nexus/SnackbarClose/SnackbarClose.vue";
import { useReportsStore } from "@/store/ReportsStore";
import { vTripleTouch } from "@/directives/doubleTouch";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

const { mdAndUp } = useDisplay();
const reportStore = useReportsStore();
const router = useRouter();
const { name: theme } = useTheme();

const { t } = useI18n();

const messageLimitSnackbar = ref(false);
const dialogDeveloperMode = ref(false);
const canAddNewReport = ref(true);

const loading = ref(false);
const finished = ref(false);

const imgHero = computed(() => {
  return theme.value === "light" ? imgHeroLight : imgHeroDark;
});

const classTheme = {
  light: "cs_on_primary",
  dark: "cs_ref_palette_primary20",
};
const className = useClassByTheme(classTheme);

const hasEmptyReports = computed(() => reportStore.$state.reports.length < 1);

function handleTripleTouch() {
  if (process.env.VUE_APP_ENV === "development")
    dialogDeveloperMode.value = true;
}

function handleDeleteReport(report: Report) {
  deleteReportAPI(report.id).then(() => {
    reportStore.removeReport(report);
    canAddNewReport.value = true;
  });
}

function handleEditReport(report: Report) {
  router.push({ name: routeNames.editReport, params: { id: report.id } });
}

function handleSelectReport(report: Report) {
  router.push({ name: routeNames.viewReport, params: { id: report.id } });
}

function handleAddReport() {
  if (!canAddNewReport.value) {
    messageLimitSnackbar.value = true;
    return;
  }
  messageLimitSnackbar.value = false;
  router.push({ name: routeNames.newReport });
}

// Metodo relacionado a paginação importado do shield
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function fetchCopyReportList() {
  loading.value = true;
  getReports({
    filters: reportStore.$state.filters,
    pagging: reportStore.$state.pagging,
  } as unknown as RequestReport)
    .then((data) => {
      debugger;
      if (finished.value) return;

      const copyReportData = structuredClone(reportStore.$state.reports);

      copyReportData.push(
        ...data.results.map((report) => ({
          ...report,
          selected: false,
        }))
      );
      reportStore.updateAllReports(copyReportData);

      canAddNewReport.value = data.canAddNewReport;

      finished.value =
        data.results.length < reportStore.$state.pagging.limit ||
        data.results.length === 0;
    })
    .finally(() => {
      loading.value = false;
    });
}

async function fetchReportList() {
  loading.value = true;
  getReports({
    filters: reportStore.$state.filters,
    pagging: reportStore.$state.pagging,
  } as unknown as RequestReport)
    .then((data) => {
      reportStore.updateAllReports(data.results);
      canAddNewReport.value = data.canAddNewReport;

      finished.value =
        data.results.length < reportStore.$state.pagging.limit ||
        data.results.length === 0;
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(() => {
  reportStore.$patch({
    filters: {
      ids: [],
      ownerIds: [],
      updatedAtFrom: null,
      updatedAtTo: null,
    },
  });
  fetchReportList();
});

// Metodo relacionado a filtragem importado do shield
// watch(
//   () => reportStore.$state.filters,
//   async () => {
//     fetchReportList();
//   },
//   {
//     deep: true,
//   }
// );
</script>
